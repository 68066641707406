import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import GetAppButton from './GetAppButton';

const Buttons = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Cont>
        <GetAppButton isApple />
        <GetAppButton />
      </Cont>
      <Cont isBottom>
        <a href='#purchase'>
          <GetAppButton isPayment isScrollButton />
        </a>
      </Cont>
      <Cont isBottom>
        <GetAppButton
          isPayment
          customText={t('purchaseGift')}
          customLink={'https://payment.cielomeditation.ru/certificate'}
        />
      </Cont>
      <Cont isBottom>
        <GetAppButton isPayment customText={t('login')} />
      </Cont>
    </div>
  );
};

const Cont = styled.div`
  display: flex;
  justify-content: center;
  ${(p) =>
    p.isBottom &&
    css`
      padding-top: 12px;
    `}
  a {
    text-decoration: none;
  }
`;

export default Buttons;
